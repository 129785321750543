import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Modal } from "react-onsenui";
import { IUser } from "../../api/domain";
import { ICoiffeur } from "../../api/domain/coiffeur";
import { IDisciplineConfiguration } from "../../api/domain/coiffeur-configuration";
import { ITeam } from "../../api/domain/team";
import { DisciplineImage, DisciplineImageSize } from "../discipline-image/discipline-image";
import "./add-coiffeur-result-popup.scss";
import { ChoosePlayer } from "./choose-player/choose-player";
import { ChoosePoints } from "./choose-points/choose-points";

interface IOwnProps {
    isOpen: boolean;
    closePopup?: () => void;
    onSave?: (
        points: number,
        discipline: IDisciplineConfiguration,
        team: ITeam,
        player: IUser,
        match: boolean,
        counterMatch: boolean
    ) => void;
    discipline: IDisciplineConfiguration;
    coiffeur: ICoiffeur;
    team: ITeam;
}

interface IAddCoiffeurResultPopupProps extends IOwnProps {}

export const AddCoiffeurResultPopup: React.FC<IAddCoiffeurResultPopupProps> = ({
    isOpen,
    discipline,
    team,
    closePopup,
    onSave,
}) => {
    const [player, setPlayer] = useState<IUser>();
    const [points, setPoints] = useState(12);
    const [match, setMatch] = useState(false);
    const [counterMatch, setCounterMatch] = useState(false);
    const save = () => {
        if (!player || !onSave) {
            return;
        }
        onSave(points, discipline, team, player, match, counterMatch);
    };

    return (
        <>
            <Modal isOpen={isOpen}>
                <div className="fixed-top p-3">
                    <h1 className="font-chalk d-flex justify-content-center align-items-center">
                        {discipline.name}
                        <DisciplineImage
                            className="text-white"
                            disciplineId={discipline.disciplineId}
                            size={DisciplineImageSize.SMALL}
                        />
                    </h1>
                </div>
                <Container>
                    <ChoosePlayer player={player} setPlayer={setPlayer} team={team} />
                    {player && (
                        <ChoosePoints
                            match={match}
                            counterMatch={counterMatch}
                            setMatch={setMatch}
                            setCounterMatch={setCounterMatch}
                            points={points}
                            setPoints={setPoints}
                        />
                    )}
                </Container>
                <div className="p-3 fixed-bottom d-flex">
                    <Button
                        onClick={closePopup}
                        variant="secondary"
                        className="font-chalk-label font-weight bold flex-grow-0"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        disabled={!player}
                        onClick={save}
                        variant={points < 10 ? "danger" : "success"}
                        className="font-chalk-label font-weight bold py-4 flex-grow-1 ml-4"
                    >
                        Speichern
                    </Button>
                </div>
            </Modal>
        </>
    );
};
