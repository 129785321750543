import classNames from "classnames";
import React from "react";
import { Button, Card, Col, Page, Row } from "react-onsenui";
import { firebase } from "../../firebase";
import Baur from "../../img/baur.png";
import "./login-page.scss";

interface IOwnProps {
  className?: string;
}

interface ILoginPageProps extends IOwnProps {}

export const LoginPage: React.FC<ILoginPageProps> = ({
  className,
  ...props
}) => {
  return (
    <Page className={classNames(className, "login-page")} {...props}>
      <div className="d-flex justify-content-center mt-5">
        <img alt="Jasskarte Baur" width={100} height={100} src={Baur} />
      </div>
      <Row verticalAlign="center">
        <Col verticalAlign="center">
          <Card className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-center">Ciao Jassbuddy</h1>
            <p className="text-center">Bitte mit Google anmelden</p>
            <div className="border d-flex justify-content-center">
              <Button
                onClick={() => {
                  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                  firebase.auth().signInWithPopup(googleAuthProvider);
                }}
              >
                Sign in with Google
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          transform: "rotate(180deg)",
        }}
        className="d-flex justify-content-center"
      >
        <img alt="Jasskarte Baur" width={100} height={100} src={Baur} />
      </div>
    </Page>
  );
};

export default LoginPage;
