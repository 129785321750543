import { useCallback, useEffect, useState } from "react";
import { store } from "react-recollect";
import { IUser } from "../api/domain";
import { storage } from "../firebase";

const NOT_EXIST_KEY = "does-not-exist";

export const useUserImage = (user?: IUser): [string | undefined, boolean, any, () => Promise<void>] => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [displayImageUrl, setDisplayImageUrl] = useState<string>();
    const update = async () => {
        setError(undefined);
        user && (await loadImageUrl(user));
    };

    const loadImageUrl = useCallback(
        async ({ uid: userId }: IUser) => {
            if (!userId || error) {
                return;
            }
            setLoading(true);
            try {
                if (!store.imageUrls[userId]) {
                    console.debug("fetchImage in useUserImage");
                }
                const url =
                    store.imageUrls[userId] || (await storage.ref(`users/${userId}/profilePicture`).getDownloadURL());
                if (url === NOT_EXIST_KEY) {
                    throw new Error("image does not exist");
                }
                store.imageUrls[userId] = url;
                setDisplayImageUrl(url);
                setError(undefined);
            } catch (e) {
                setError(e);
                store.imageUrls[userId] = NOT_EXIST_KEY;
            } finally {
                setLoading(false);
            }
        },
        [error]
    );
    useEffect(() => {
        user && loadImageUrl(user);
    }, [user, loadImageUrl]);

    return [displayImageUrl, loading, error, update];
};
