import classNames from "classnames";
import React, { useMemo } from "react";
import { Bar, CartesianGrid, Cell, ComposedChart, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { IDisciplineSingleStatistic } from "../../../api/domain";
import "./players-and-teams-single-discipline-chart.scss";

interface IOwnProps {
    className?: string;
    statistics: IDisciplineSingleStatistic[];
}

const getLabelOfDisciplineSingleStatistic = (disciplineSingleStatistic: IDisciplineSingleStatistic) => {
    if (disciplineSingleStatistic.displayName) {
        return disciplineSingleStatistic.displayName;
    }
    if (!disciplineSingleStatistic.members) {
        return "";
    }
    const memberIds = Object.keys(disciplineSingleStatistic.members).sort();
    return `${disciplineSingleStatistic.members[memberIds[0]].displayName} & ${
        disciplineSingleStatistic.members[memberIds[1]].displayName
    }`;
};

interface IPlayersAndTeamsSingleDisciplineChartProps extends IOwnProps {}

interface IDisciplineSingleStatisticWithLabel extends IDisciplineSingleStatistic {
    label: string;
    winQuota: number;
    tieQuota: number;
    lossQuota: number;
    winQuotaLabel: string;
}

export const PlayersAndTeamsSingleDisciplineChart: React.FC<IPlayersAndTeamsSingleDisciplineChartProps> = ({
    className,
    statistics,
}) => {
    const data: IDisciplineSingleStatisticWithLabel[] = useMemo(
        () =>
            statistics.map((statistic) => ({
                ...statistic,
                label: getLabelOfDisciplineSingleStatistic(statistic),
                winQuota: Math.round((statistic.numberOfTimesWon * 100) / statistic.numberOfTimesPlayed),
                lossQuota: Math.round((statistic.numberOfTimesLost * 100) / statistic.numberOfTimesPlayed),
                tieQuota: Math.round((statistic.numberOfTimesTie * 100) / statistic.numberOfTimesPlayed),
                winQuotaLabel: statistic.numberOfTimesWon
                    ? `${Math.round((statistic.numberOfTimesWon * 100) / statistic.numberOfTimesPlayed)} %`
                    : "",
                winQuotaRateLabel: `${statistic.numberOfTimesWon} / ${statistic.numberOfTimesPlayed}`,
            })),
        [statistics]
    );

    return (
        <div className={classNames(className, "players-and-teams-single-discipline-chart")}>
            <ResponsiveContainer width="100%" height={480}>
                <ComposedChart layout="vertical" data={data} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                    {/*<XAxis dataKey="label" />*/}
                    {/*/!*<Tooltip />*!/*/}
                    {/*<CartesianGrid stroke="#f5f5f5" />*/}
                    {/*<Line isAnimationActive={false} type="monotone" dataKey="uv" stroke="#ff7300" yAxisId={0} />*/}
                    {/*<Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} />*/}
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis
                        xAxisId="points"
                        type="number"
                        domain={[0, 16]}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        minTickGap={0}
                        orientation="top"
                    >
                        <Label fill="rgba(0,0,0,.5)" value="Punkte" offset={0} position="insideTop" />
                    </XAxis>
                    <XAxis
                        xAxisId="percentage"
                        type="number"
                        domain={[0, 100]}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        minTickGap={0}
                        unit=" %"
                    >
                        <Label fill="rgba(0,0,0,.3)" value="Gewinnquote" offset={0} position="insideBottom" />
                    </XAxis>
                    <YAxis interval={0} type="category" dataKey="label" tickLine={false} axisLine={false} hide={true} />
                    <Bar
                        radius={[2, 5, 5, 2]}
                        xAxisId="points"
                        barSize={15}
                        isAnimationActive={false}
                        dataKey="averagePoints"
                        fill="rgba(0,154,250,1)"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.totalPoints ? "#3ca1f5" : "#8d8d8d"} />
                        ))}
                        <LabelList
                            formatter={(val) => val.toLocaleString().replace(/\s/g, "\u00A0")}
                            className="bar-label bar-label-white"
                            dataKey="label"
                            position="inside"
                        />
                        <LabelList className="bar-label" dataKey="averagePoints" position="right" />
                    </Bar>
                    <Bar
                        radius={2}
                        stackId="a"
                        xAxisId="percentage"
                        barSize={3}
                        isAnimationActive={false}
                        dataKey="winQuota"
                        fill="rgba(50,205,50,1)"
                    >
                        <LabelList className="bar-label bar-label-green" dataKey="winQuotaLabel" position="bottom" />
                    </Bar>
                    <Bar
                        radius={2}
                        stackId="a"
                        xAxisId="percentage"
                        barSize={3}
                        isAnimationActive={false}
                        dataKey="tieQuota"
                        fill="rgba(0,0,0,0.1)"
                    >
                        <LabelList className="bar-label bar-label-gray" dataKey="winQuotaRateLabel" position="bottom" />
                    </Bar>
                    <Bar
                        radius={2}
                        stackId="a"
                        xAxisId="percentage"
                        barSize={3}
                        isAnimationActive={false}
                        dataKey="lossQuota"
                        fill="rgba(256,0,0,0.3)"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};
