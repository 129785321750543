import React, { useState } from "react";
import { Icon, Page as OnsenPage, PullHook, Splitter, SplitterContent, SplitterSide } from "react-onsenui";
import { collect, WithStoreProp } from "react-recollect";
import { Navigation } from "../navigation/navigation";
import { Toolbar } from "../toolbar/toolbar";
import "./page.scss";

interface IOwnProps {
    className?: string;
    title?: string;
    onBack?: () => void;
    onNext?: () => void;
    hideToolbar?: boolean;
    onUpdate?: () => Promise<any>;
    fixedPull?: boolean;
    classNamePullHook?: string;
    swipeable?: boolean;
}

interface IPageProps extends IOwnProps, WithStoreProp {}

const InternalPage: React.FC<IPageProps> = ({
    children,
    title,
    onBack,
    onNext,
    hideToolbar,
    store,
    onUpdate,
    fixedPull,
    classNamePullHook,
    swipeable = true,
}) => {
    const toggleMenu = () => (store.isMenuOpen = !store.isMenuOpen);
    const closeMenu = () => (store.isMenuOpen = false);
    const openMenu = () => (store.isMenuOpen = true);
    const [pullHookState, setPullHookState] = useState("initial");
    const onPullHookAction = async (done: () => void) => {
        if (!onUpdate) {
            return;
        }
        await onUpdate();
        done();
    };

    return (
        <Splitter>
            <SplitterSide
                side="left"
                width={250}
                isOpen={store.isMenuOpen}
                collapse={true}
                swipeable={swipeable}
                onClose={closeMenu}
                onOpen={openMenu}
            >
                <Navigation />
            </SplitterSide>
            <SplitterContent>
                <OnsenPage
                    renderToolbar={() =>
                        !hideToolbar && (
                            <Toolbar onMenuToggle={toggleMenu} onBack={onBack} title={title} onNext={onNext} />
                        )
                    }
                >
                    <PullHook
                        fixedContent={fixedPull}
                        disabled={!onUpdate}
                        onChange={(e) => setPullHookState(e.state)}
                        onLoad={onPullHookAction}
                    >
                        <div className={classNamePullHook}>
                            {pullHookState === "initial" ? (
                                <span>
                                    <Icon size={35} spin={false} icon="ion-arrow-down-a" />
                                    Ziehen um zu aktualisieren
                                </span>
                            ) : pullHookState === "preaction" ? (
                                <span>
                                    <Icon size={35} spin={false} icon="ion-arrow-up-a" />
                                    Loslassen um zu aktualisieren
                                </span>
                            ) : (
                                <span>
                                    <Icon size={35} spin={true} icon="ion-load-d"></Icon> Aktualisiere...
                                </span>
                            )}
                        </div>
                    </PullHook>
                    {children}
                </OnsenPage>
            </SplitterContent>
        </Splitter>
    );
};

export const Page = collect(InternalPage);
