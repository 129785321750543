import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { List, ListHeader, ListItem, Page } from "react-onsenui";
import { useHistory } from "react-router";
import { ICoiffeur } from "../../api/domain/coiffeur";
import { ISchieber } from "../../api/domain/schieber";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { SchieberService } from "../../api/service/schieber-service";
import { firebase } from "../../firebase";
import { useUser } from "../../hooks/use-user";
import CoiffeurTafel from "../../img/slate_coiffeur.png";
import SchieberTafel from "../../img/slate_schieber.png";
import { formatTimestampDate } from "../../util/date-helpers";
import { UserImage, UserImageSize } from "../user-image/user-image";
import "./navigation.scss";
import BaurKopf from "../../img/baur-kopf.png";

interface IOwnProps {
    className?: string;
}

interface INavigationProps extends IOwnProps {}

export const Navigation: React.FC<INavigationProps> = () => {
    const logout = () => {
        firebase.auth().signOut();
    };
    const [user] = useUser();
    const history = useHistory();
    const [ongoingCoiffeurGames] = useCollectionData<ICoiffeur>(CoiffeurService.getOngoingCoiffeurGamesQuery());
    const [ongoingSchieberGames] = useCollectionData<ISchieber>(SchieberService.getOngoingSchieberGamesQuery());
    const hasOngoingGames =
        (ongoingCoiffeurGames && ongoingCoiffeurGames.length > 0) ||
        (ongoingSchieberGames && ongoingSchieberGames.length > 0);

    return (
        <Page>
            <List>
                <ListHeader className="bg-japanese-cubes">
                    <div onClick={() => history.push("/")}>
                        <div className="d-flex pt-2">
                            <UserImage
                                size={UserImageSize.EXTRA_SMALL}
                                user={user}
                                rounded={true}
                                classNameImage="border border-white border-2"
                            />
                        </div>
                        <div className="d-flex flex-column pt-4 text-white">
                            <span className="font-weight-bold">{user?.realName}</span>
                            <span>{user?.email}</span>
                        </div>
                    </div>
                </ListHeader>
                {hasOngoingGames && <ListHeader>Weiterspielen</ListHeader>}
                {ongoingCoiffeurGames &&
                    ongoingCoiffeurGames.map((game) => (
                        <ListItem onClick={() => history.push(`/coiffeur/${game.uid}`)} key={game.uid}>
                            <div className="left">
                                <img alt="Coiffeur Jasstafel" width={20} src={CoiffeurTafel} />
                            </div>
                            <div className="center">
                                <span className="list-item__title">Coiffeur</span>
                                <span className="list-item__subtitle">
                                    Gestartet am {formatTimestampDate(game.date)}
                                </span>
                            </div>
                        </ListItem>
                    ))}
                {ongoingSchieberGames &&
                    ongoingSchieberGames.map((game) => (
                        <ListItem onClick={() => history.push(`/schieber/${game.uid}`)} key={game.uid}>
                            <div className="left">
                                <img alt="Schieber Jasstafel" width={20} src={SchieberTafel} />
                            </div>
                            <div className="center">
                                <span className="list-item__title">Schieber</span>
                                <span className="list-item__subtitle">
                                    Gestartet am {formatTimestampDate(game.date)}
                                </span>
                            </div>
                        </ListItem>
                    ))}
                <ListHeader>Neues Spiel</ListHeader>
                <ListItem onClick={() => history.push("/neuer-coiffeur")}>
                    <div className="left">
                        <img alt="Coiffeur Jasstafel" width={20} src={CoiffeurTafel} />
                    </div>
                    <div className="center">Coiffeur</div>
                </ListItem>
                <ListItem onClick={() => history.push("/neuer-schieber")}>
                    <div className="left">
                        <img alt="Schieber Jasstafel" width={20} src={SchieberTafel} />
                    </div>
                    <div className="center">Schieber</div>
                </ListItem>
                <ListHeader>Sonstiges</ListHeader>
                <ListItem onClick={() => history.push("/statistik")}>
                    <div className="py-2">Statistik</div>
                </ListItem>
                <ListItem onClick={() => history.push("/strichliste")}>
                    <div className="py-2">Strichliste</div>
                </ListItem>
                <ListItem onClick={() => history.push("/alle-spiele")}>
                    <div className="py-2">Alle Spiele</div>
                </ListItem>
                <ListItem onClick={() => history.push("/profile")}>
                    <div className="py-2">Profil</div>
                </ListItem>
                <ListItem onClick={() => history.push("/jassgruppen")}>
                    <div className="py-2">Jassgruppen</div>
                </ListItem>
                <ListItem onClick={() => history.push("/jasskasse")}>
                    <div className="py-2">Jasskasse</div>
                </ListItem>
                <div className="py-5" />
                <ListItem>
                    <div className="d-flex justify-content-around w-100 align-items-center">
                        <div className="text-secondary">&copy; Jassgott {new Date().getFullYear()}</div>
                        <img alt="Baur Kopf" width={45} height={45} src={BaurKopf} />
                    </div>
                </ListItem>
                <ListItem className="bg-danger font-weight-bold text-white" onClick={logout}>
                    <div className="py-2">Abmelden</div>
                </ListItem>
            </List>
        </Page>
    );
};
