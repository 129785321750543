import React from "react";
import { Modal } from "react-onsenui";
import { useParams } from "react-router";
import { Page } from "../../components/page/page";
import { ProgressIndicator } from "../../components/progress-indicator/progress-indicator";
import { CoiffeurSlate } from "../../components/coiffeur-slate/coiffeur-slate";
import { useCoiffeur } from "../../hooks/use-coiffeur";
import "./coiffeur-page.scss";

interface IOwnProps {
    className?: string;
}

interface ICoiffeurPageProps extends IOwnProps {}

export const CoiffeurPage: React.FC<ICoiffeurPageProps> = ({ className }) => {
    let { id } = useParams();
    const [coiffeur, loading, , , addResult, deleteResult] = useCoiffeur(id);

    return (
        <Page hideToolbar={true} className="coiffeur-page">
            <div className="coiffeur-page__slate">
                {coiffeur && <CoiffeurSlate addResult={addResult} coiffeur={coiffeur} deleteResult={deleteResult} />}
            </div>
            <Modal isOpen={loading}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <ProgressIndicator />
                </div>
            </Modal>
        </Page>
    );
};
