import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Icon } from "react-onsenui";
import { collect, WithStoreProp } from "react-recollect";
import { storage } from "../../firebase";
import "./discipline-image.scss";

export enum DisciplineImageSize {
    LARGE = "lg",
    MEDIUM = "md",
    SMALL = "sm",
    EXTRA_SMALL = "xs",
}

interface IOwnProps {
    disciplineId: string;
    className?: string;
    size: DisciplineImageSize;
    rounded?: boolean;
    inverted?: boolean;
}

interface IDisciplineImageProps extends IOwnProps, WithStoreProp {}

const InternalDisciplineImage: React.FC<IDisciplineImageProps> = ({
    className,
    disciplineId,
    size,
    rounded,
    inverted,
    store,
}) => {
    const [displayImageUrl, setDisplayImageUrl] = useState();
    const [style, setStyle] = useState<React.CSSProperties>();
    const setStyleByImageUrl = (url: string) => setStyle({ backgroundImage: `url(${url})` });
    useEffect(() => {
        const imageUrl = store.imageUrls[disciplineId];
        if (imageUrl) {
            setDisplayImageUrl(imageUrl);
            setStyleByImageUrl(imageUrl);
        } else {
            console.debug("fetch discipline image");
            storage
                .ref(`disciplines/${disciplineId}.png`)
                .getDownloadURL()
                .then((url) => {
                    setDisplayImageUrl(url);
                    store.imageUrls[disciplineId] = url;
                    setStyleByImageUrl(url);
                });
        }
        // eslint-disable-next-line
    }, [disciplineId]);

    return (
        <picture className={classNames(className, "discipline-image d-flex justify-content-center")}>
            <div
                className={classNames(
                    `discipline-image__preview discipline-image__preview--${size} d-flex justify-content-center align-items-center`,
                    rounded && "discipline-image__preview--rounded",
                    inverted && "discipline-image__preview--inverted"
                )}
                style={style}
            >
                {!displayImageUrl && <Icon className={`discipline-image__fallback--${size}`} icon="md-category" />}
            </div>
        </picture>
    );
};

export const DisciplineImage = collect(InternalDisciplineImage);
