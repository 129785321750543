import classNames from "classnames";
import React from "react";
import Skeleton from "react-loading-skeleton/lib";
import { Card, Icon, List } from "react-onsenui";
import { IUser } from "../../../api/domain";
import { ICoiffeur } from "../../../api/domain/coiffeur";
import { useUser } from "../../../hooks/use-user";
import CoiffeurTafel from "../../../img/slate_coiffeur.png";
import { formatTimestampDate } from "../../../util/date-helpers";
import { UserListItem } from "../../list-items/user-list-item/user-list-item";
import "./coiffeur-card.scss";

interface IOwnProps {
    className?: string;
    game: ICoiffeur;
    onClick?: () => void;
}

interface ICoiffeurCardProps extends IOwnProps {}

export const CoiffeurCard: React.FC<ICoiffeurCardProps> = ({ className, game, onClick }) => {
    const [user, loading] = useUser();
    const won = user && game.winners && !!game.winners[user.uid];
    const winners: IUser[] | undefined = game.winners && Object.keys(game.winners).map((key) => game.winners![key]);

    return (
        <Card className={classNames(className, "coiffeur-card")}>
            <div onClick={onClick} className="w-100 h-100">
                <div className="text-secondary d-flex">
                    {formatTimestampDate(game.date)}
                    <div className={classNames("ml-3 font-weight-bold", won ? "text-success" : "text-danger")}>
                        <Icon icon={won ? "fa-trophy" : "fa-thumbs-down"} className="mr-2" />
                        {!loading ? won ? "Gewonnen" : "Verloren" : <Skeleton />}
                    </div>
                    <img alt="Coiffeur Jasstafel" className="ml-auto" width={20} src={CoiffeurTafel} />
                </div>
                <div className="font-weight-bold text-center pt-2">Gewinner:</div>
                <List>{winners && winners.map((user) => <UserListItem user={user} />)}</List>
            </div>
        </Card>
    );
};
