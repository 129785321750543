import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfigDEV = {
    apiKey: "AIzaSyC2GHGq3tgZEbGRsoJU1QqNGxtfm0vmDNY",
    authDomain: "jassgott-dev.firebaseapp.com",
    databaseURL: "https://jassgott-dev.firebaseio.com",
    projectId: "jassgott-dev",
    storageBucket: "jassgott-dev.appspot.com",
    messagingSenderId: "698553458767",
    appId: "1:698553458767:web:f0daeb3057b03da02f8a3a",
};
const fireBaseConfigPROD = {
    apiKey: "AIzaSyBrbhUjzVeBchq4wxxiyy6VmzVhEmfLZhw",
    projectId: "jassgott",
    databaseURL: "https://jassgott.firebaseio.com",
    authDomain: "jassgott.firebaseapp.com",
    // OPTIONAL
    storageBucket: "gs://jassgott.appspot.com",
    // messagingSenderId: "MESSAGING_SENDER_ID"
};

const fireBaseConfig = process.env.NODE_ENV === "development" ? firebaseConfigDEV : fireBaseConfigPROD;

firebase.initializeApp(fireBaseConfig);
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.app().functions("europe-west3");
if (process.env.NODE_ENV === "development") {
    functions.useFunctionsEmulator("http://localhost:5001");
}

export { firebase, firestore, storage, functions };
