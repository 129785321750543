import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ISignedInUser, IUser } from "../api/domain";
import { UserService } from "../api/service/user-service";
import { firebase } from "../firebase";
import { updateUser as updateUserExternal } from "../store/updaters/updateUser";

export const useUser = (): [
    ISignedInUser | undefined,
    boolean,
    any,
    () => Promise<void>,
    (user: Partial<IUser>) => Promise<void>
] => {
    const [firebaseUser] = useAuthState(firebase.auth());
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<ISignedInUser>();
    const [error, setError] = useState();

    const updateUser = async (user: Partial<IUser>) => {
        await updateUserExternal(user);
        await fetchUser();
    };
    const fetchUser = async () => {
        setLoading(true);
        try {
            const user = await UserService.getCurrentUser();
            setUser({
                ...user,
                email: firebaseUser?.email || "",
                photoUrl: firebaseUser?.photoURL || "",
                realName: firebaseUser?.displayName || "",
            });
        } catch (e) {
            setUser(undefined);
            setError(e);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line
    }, [firebaseUser]);

    return [user, loading, error, fetchUser, updateUser];
};
