import React from "react";
import { Modal } from "react-onsenui";
import { useParams } from "react-router";
import { Page } from "../../components/page/page";
import { ProgressIndicator } from "../../components/progress-indicator/progress-indicator";
import { SchieberSlate } from "../../components/schieber-slate/schieber-slate";
import { useSchieber } from "../../hooks/use-schieber";
import "./schieber-page.scss";

interface IOwnProps {
    className?: string;
}

interface ISchieberPageProps extends IOwnProps {}

export const SchieberPage: React.FC<ISchieberPageProps> = ({ className }) => {
    let { id } = useParams();
    const [schieber, loading, , addMark, removeMark, setWinner] = useSchieber(id);

    return (
        <Page hideToolbar={true} className="schieber-page">
            <div className="coiffeur-page__slate">
                {schieber && (
                    <SchieberSlate
                        setWinner={setWinner}
                        addMark={addMark}
                        removeMark={removeMark}
                        schieber={schieber}
                    />
                )}
            </div>
            <Modal isOpen={loading}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <ProgressIndicator />
                </div>
            </Modal>
            {/*<Modal isOpen={!!schieber?.winners}>*/}
            {/*    <Container className="font-crayon text-1-3">*/}
            {/*        Der Schieber wurde beendet.*/}
            {/*    </Container>*/}
            {/*    <div className="p-3 fixed-bottom d-flex">*/}
            {/*        <BootstrapButton*/}
            {/*            onClick={setWinner.bind(null, undefined)}*/}
            {/*            variant="danger"*/}
            {/*            className="font-chalk-label font-weight bold py-4 flex-grow-1 ml-4"*/}
            {/*        >*/}
            {/*            Gewinner zurücksetzen*/}
            {/*        </BootstrapButton>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </Page>
    );
};
