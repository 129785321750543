import { useEffect, useState } from "react";
import { IResolvedGroup } from "../api/domain/group";
import { fetchGroups } from "../store/updaters/fetchGroups";

export const useGroups = (): [IResolvedGroup[], boolean, any, () => Promise<void>] => {
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState<IResolvedGroup[]>([]);
    const [error, setError] = useState();

    const onFetchGroup = async () => {
        setLoading(true);
        try {
            const groups = await fetchGroups();
            setGroups(groups);
        } catch (e) {
            setGroups([]);
            setError(e);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        onFetchGroup();
    }, []);

    return [groups, loading, error, onFetchGroup];
};
